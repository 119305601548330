import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { TextareaAutosize } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
// import Backdrop from "@mui/material/Backdrop";
import {
  updateToDo,
  updateOnProgress,
  updateCompleted,
} from "../../redux/todoApp/todoAppSlice";
import { useAppDispatch } from "../../redux/hooks";
import {
  deleteToDo,
  deleteOnProgress,
  deleteCompleted,
} from "../../redux/todoApp/todoAppSlice";
import moment from "moment";
import "./index.scss";
import { Draggable } from "react-beautiful-dnd";
import { TextCardProps } from "../../models/models";

const TextCard: React.FC<TextCardProps> = React.memo(
  ({ title, data, index }) => {
    const [isHovering, setIsHovering] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    // const [backDropOpener, setBackDropOpener] = useState<boolean>(false);
    const [currentEditingText, setCurrentEditingText] = useState<string>("");
    const saveRef = useRef<HTMLButtonElement>(null);
    const textAreaDivRef = useRef<HTMLDivElement>(null);
    const dispatch = useAppDispatch();

    // useEffect(() => {
    //   if (isEditing) {
    //     setBackDropOpener(true);
    //   }
    //   if (!isEditing) {
    //     setBackDropOpener(false);
    //   }
    // }, [isEditing, setIsEditing]);

    useEffect(() => {
      let handler = (event: any) => {
        if (
          !saveRef.current?.contains(event.target) &&
          !textAreaDivRef.current?.contains(event.target)
        ) {
          setIsEditing(false);
          setCurrentEditingText("");
        }
      };

      document.addEventListener("mousedown", handler);

      return () => {
        document.removeEventListener("mousedown", handler);
      };
    }, [saveRef, textAreaDivRef]);

    const handleEditingTodo = (): void => {
      if (
        currentEditingText.length > 0 &&
        currentEditingText !== data[1].text
      ) {
        if (title === "To Do") {
          dispatch(
            updateToDo({
              id: data[0],
              createdAt: data[1].createdAt,
              text: currentEditingText,
            })
          );
          setIsEditing(false);
          setCurrentEditingText("");
        }
        if (title === "On Progress") {
          dispatch(
            updateOnProgress({
              id: data[0],
              createdAt: data[1].createdAt,
              text: currentEditingText,
            })
          );
          setIsEditing(false);
          setCurrentEditingText("");
        }
        if (title === "Completed") {
          dispatch(
            updateCompleted({
              id: data[0],
              createdAt: data[1].createdAt,
              text: currentEditingText,
            })
          );
          setIsEditing(false);
          setCurrentEditingText("");
        }
      } else {
        setIsEditing(false);
        setCurrentEditingText("");
      }
    };

    return (
      <>
        {isEditing ? (
          <>
            {/* <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={backDropOpener}
            > */}
            <div ref={textAreaDivRef}>
              <TextareaAutosize
                className="text_card_text_area"
                minRows={2}
                value={currentEditingText}
                onChange={(e) => setCurrentEditingText(e.target.value)}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    handleEditingTodo();
                  }
                }}
                autoFocus
                //@ts-ignore
                maxLength="120"
                onFocus={(e) =>
                  e.currentTarget.setSelectionRange(
                    e.currentTarget.value.length,
                    e.currentTarget.value.length
                  )
                }
              />
            </div>

            <div className="text_card_new_card_checker">
              <button
                className="text_card_new_card_save_button"
                ref={saveRef}
                onClick={() => handleEditingTodo()}
              >
                Save
              </button>

              <CloseIcon
                onClick={() => setIsEditing(false)}
                className="text_card_new_card_cancel_button"
              />
            </div>
            {/* </Backdrop> */}
          </>
        ) : (
          <div>
            <Draggable key={data[0]} draggableId={data[0]} index={index}>
              {(provided, snapshot) => (
                <div
                  className="todo_text_cards"
                  onMouseEnter={() => setIsHovering(true)}
                  onMouseLeave={() => setIsHovering(false)}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <p>{data && data[1].text}</p>
                  <EditIcon
                    className={`card_edit_icon ${isHovering ? "show" : "hide"}`}
                    onClick={() => {
                      setCurrentEditingText(data && data[1].text);
                      setIsEditing(true);
                      setIsHovering(false);
                    }}
                  />
                  <DeleteIcon
                    className={`card_delete_icon ${
                      isHovering ? "show" : "hide"
                    }`}
                    onClick={() => {
                      if (title === "To Do") dispatch(deleteToDo(data[0]));
                      if (title === "On Progress")
                        dispatch(deleteOnProgress(data[0]));
                      if (title === "Completed")
                        dispatch(deleteCompleted(data[0]));
                    }}
                  />
                </div>
              )}
            </Draggable>
            <div
              className={`todo_text_card_time ${isHovering ? "show" : "hide"}`}
            >
              {moment(data && data[1].createdAt).fromNow()}
            </div>
          </div>
        )}
      </>
    );
  }
);

export default TextCard;
