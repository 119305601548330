import { useRef, useEffect, useState } from "react";
import Header from "../../components/Header";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  writeNewToDo,
  moveToDoCard,
  removeToDoCard,
} from "../../redux/todoApp/todoAppSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import "./index.scss";
import ToDoCard from "../../components/ToDoCard";
import SnackBar from "../../components/SnackBar";
import { ArrayToDoValue } from "../../models/models";
import { showSnackBar } from "../../redux/snackBar/snackBarSlice";

const TodoApp: React.FC = () => {
  const toDoAppData = useAppSelector((state) => state.todoApp);
  const dispatch = useAppDispatch();
  const [toDo, setToDo] = useState<any>([]);
  const [onProgress, setOnProgress] = useState<any>([]);
  const [completed, setCompleted] = useState<any>([]);
  // const [toDo, setToDo] = useState<ArrayToDoValue[] | []>([]);
  // const [onProgress, setOnProgress] = useState<ArrayToDoValue[] | []>([]);
  // const [completed, setCompleted] = useState<ArrayToDoValue[] | []>([]);
  const addTodoRef = useRef<HTMLInputElement>(null);
  const [currentAddingItem, setCurrentAddingItem] = useState<string>("");

  //for changing locally if changes occur in database
  useEffect(() => {
    if (toDoAppData?.toDo) {
      setToDo(Object.entries(toDoAppData.toDo));
    } else {
      setToDo([]);
    }
  }, [toDoAppData.toDo]);

  useEffect(() => {
    if (toDoAppData?.onProgress) {
      setOnProgress(Object.entries(toDoAppData.onProgress));
    } else {
      setOnProgress([]);
    }
  }, [toDoAppData.onProgress]);

  useEffect(() => {
    if (toDoAppData?.completed) {
      setCompleted(Object.entries(toDoAppData.completed));
    } else {
      setCompleted([]);
    }
  }, [toDoAppData.completed]);

  useEffect(() => {
    if (window.innerWidth > 1000) {
      addTodoRef.current?.focus();
    }
  }, []);

  const onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    //for removing items in database
    dispatch(
      removeToDoCard({
        removeFrom: source.droppableId,
        removeId: draggableId,
      })
    );

    const spliceArrayAndDataBase = (target: string): ArrayToDoValue[] => {
      const db =
        target === "toDo"
          ? toDo
          : target === "onProgress"
          ? onProgress
          : completed;

      const active: any = db.filter((el: string) => el[0] !== draggableId);

      active.splice(destination.index, 0, [
        draggableId,
        {
          createdAt: toDoAppData[source.droppableId][draggableId].createdAt,
          text: toDoAppData[source.droppableId][draggableId].text,
        },
      ]);

      target === "toDo"
        ? setToDo(active)
        : target === "onProgress"
        ? setOnProgress(active)
        : setCompleted(active);
      return active;
    };

    const checkAndMoveData = (): any => {
      if (toDoAppData[destination.droppableId]) {
        if (destination.droppableId === "toDo") {
          return spliceArrayAndDataBase("toDo");
        }
        if (destination.droppableId === "onProgress") {
          return spliceArrayAndDataBase("onProgress");
        }
        if (destination.droppableId === "completed") {
          return spliceArrayAndDataBase("completed");
        }
      } else {
        const data = {
          createdAt: toDoAppData[source.droppableId][draggableId].createdAt,
          text: toDoAppData[source.droppableId][draggableId].text,
        };

        if (destination.droppableId === "toDo") {
          setToDo([[draggableId, data]]);
        }
        if (destination.droppableId === "onProgress") {
          setOnProgress([[draggableId, data]]);
        }
        if (destination.droppableId === "completed") {
          setCompleted([[draggableId, data]]);
        }
        return data;
      }
    };

    //for moving data in db
    dispatch(
      moveToDoCard({
        moveTo: destination.droppableId,
        movingData: checkAndMoveData(),
      })
    );
  };

  return (
    <div className="TodoApp_container">
      <Header />
      <div className="TodoApp_name"> Todo App</div>
      <div className="Todo_Add_button_container">
        <input
          type="text"
          // @ts-ignore
          maxLength="120"
          placeholder="Add Todo items..."
          className="Todo_Add_button"
          ref={addTodoRef}
          value={currentAddingItem}
          onChange={(e) => setCurrentAddingItem(e.target.value)}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              if (currentAddingItem.replace(/ /g, "").length === 0) {
                setCurrentAddingItem("");
                return;
              }
              if (toDo.length >= 10) {
                dispatch(
                  showSnackBar({
                    text: "Max todo items reached",
                    type: "error",
                  })
                );
                setCurrentAddingItem("");
                return;
              }
              if (currentAddingItem.length > 0) {
                dispatch(writeNewToDo(currentAddingItem));
                setCurrentAddingItem("");
              }
            }
          }}
        />
        <AddCircleIcon
          sx={{ fontSize: "2rem" }}
          className="Todo_Add_button_icon"
          onClick={() => {
            if (currentAddingItem.replace(/ /g, "").length === 0) {
              setCurrentAddingItem("");
              return;
            }
            if (toDo.length >= 10) {
              dispatch(
                showSnackBar({
                  text: "Max todo items reached",
                  type: "error",
                })
              );
              setCurrentAddingItem("");
              return;
            }
            if (currentAddingItem.length > 0) {
              dispatch(writeNewToDo(currentAddingItem));
              setCurrentAddingItem("");
            }
          }}
        />
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <div className="Todos_container">
          <ToDoCard title="To Do" data={toDo} droppableId="toDo" />

          <ToDoCard
            title="On Progress"
            data={onProgress}
            droppableId="onProgress"
          />

          <ToDoCard
            title="Completed"
            data={completed}
            droppableId="completed"
          />
        </div>
      </DragDropContext>
      <SnackBar />
    </div>
  );
};

export default TodoApp;
