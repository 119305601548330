import "./index.scss";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const SocialMedia: React.FC = () => {
  return (
    <div className="social_media_Links">
      <a
        href="https://www.facebook.com/prabin.thakur.334"
        target="_blank"
        rel="noreferrer"
      >
        <FacebookIcon className="social_media_icons" />
      </a>
      <a
        href="https://www.instagram.com/unseenerror_/"
        target="_blank"
        rel="noreferrer"
      >
        <InstagramIcon className="social_media_icons" />
      </a>
      <a
        href="https://www.linkedin.com/in/prabin-thakur-b88248216/"
        target="_blank"
        rel="noreferrer"
      >
        <LinkedInIcon className="social_media_icons" />
      </a>
      <a
        href="https://twitter.com/PrabinT12102398"
        target="_blank"
        rel="noreferrer"
      >
        <TwitterIcon className="social_media_icons" />
      </a>
      <a
        href="https://github.com/Prabin-Thakur"
        target="_blank"
        rel="noreferrer"
      >
        <GitHubIcon className="social_media_icons" />
      </a>
    </div>
  );
};

export default SocialMedia;
