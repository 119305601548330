import React from "react";
import "./index.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import imagei from "../../assets/images/imagei.jpg";
import imageii from "../../assets/images/imageii.jpg";
import imageiii from "../../assets/images/imageiii.jpg";
import imageiv from "../../assets/images/imageiv.jpg";
import imagev from "../../assets/images/imagev.jpg";
import imagevi from "../../assets/images/imagevi.jpg";
import imagevii from "../../assets/images/imagevii.jpg";
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
// import { CustomArrowProps } from "react-slick";

const images = [imagei, imageii, imageiii, imageiv, imagev, imagevi, imagevii];

// custom arrow for next
// function SamplePrevArrow(props: CustomArrowProps) {
//   const { className, onClick } = props;
//   return (
//     <div className={className} onClick={onClick}>
//       <TrendingFlatIcon className="projects_prev_icon_carousel" />
//     </div>
//   );
// }

// // custom arrow for previous
// function SampleNextArrow(props: CustomArrowProps) {
//   const { className, onClick } = props;
//   return (
//     <div className={className} onClick={onClick}>
//       <TrendingFlatIcon className="projects_next_icon_carousel" />
//     </div>
//   );
// }

const GalleryCarousel: React.FC = () => {
  const navigate = useNavigate();

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500, //animation speed when sliding
    autoplaySpeed: 6000, //Delay between each auto scroll
    vertical: false,
    pauseOnFocus: false, //Prevents autoplay while focused on slides
    swipeToSlide: true, //Enable drag/swipe irrespective of `slidesToScroll`
    pauseOnHover: false,
    // prevArrow: <SamplePrevArrow />,
    // nextArrow: <SampleNextArrow />,
  };

  return (
    <div className="gallery_carousel_container">
      <Slider {...settings} className="gallery_carousel_slider">
        {images.map((image, index) => {
          return (
            <img
              src={image}
              alt={image}
              key={image}
              className="gallery_carousel_image"
            />
          );
        })}
      </Slider>
      <div className="gallery_carousel_contents">
        <p className="gallery_carousel_title">Gallery</p>
        <div
          className="gallery_carousel_navigate"
          onClick={() => navigate("/gallery")}
        >
          <p className="gallery_carousel_navigate_link">Check my gallery</p>
          <KeyboardBackspaceRoundedIcon className="gallery_carousel_navigate_icon" />
        </div>
      </div>
    </div>
  );
};

export default GalleryCarousel;
