import { useEffect } from "react";
import "./index.scss";
import Header from "../../components/Header";
import SnackBar from "../../components/SnackBar";
import developerA from "../../assets/images/developerA.jpg";
import Contact from "../../components/Contact";
import { useAppDispatch } from "../../redux/hooks";
import { showContact } from "../../redux/contact/contactSlice";

const About: React.FC = () => {
  const dispatch = useAppDispatch();
  //to make sure when page first loads you are on top of the page
  useEffect(() => {
    window.scrollTo(0, 0); // scrolls to the top of the page
  }, []);

  return (
    <div className="about_container">
      <Header />
      <div className="about_container_contents">
        <div className="about_container_content1">
          <div className="about_container_description_title">ABOUT</div>
          <div className="about_container_description">
            Hey! My name is Prabin Thakur. I am a front-end developer, visual
            artist, and an avid explorer. My love for coding showcases my
            ability to think analytically and solve problems. Meanwhile, my
            interest in different types of art allows me to express my
            imaginative and creative side.
            <br />
            Some other things that I love: visuals, arts, coding, technologies,
            photography, films, instagram, anime, manga, film & digital cameras,
            details, evening walks, animals. If something looks a little
            different or something piques my curiosity, you are damn right i am
            gonna explore it. I am always in awe of the power of storytelling
            and visual arts.At my very core, I am just a young buck on a
            journey, unceasingly refining and establishing myself as an artist,
            a developer, and a young man. Feel free to bond with me over any of
            the aforementioned items. I'm sure we'll get along.
            <br />
            <br />
            Thank you for taking the time to get to know me, and I hope you
            enjoy browsing my portfolio! For more, feel free to reach me through
            the contact tab up top or by clicking{" "}
            <strong
              onClick={() => {
                dispatch(showContact());
              }}
            >
              here
            </strong>
            .Much love!
          </div>
        </div>
        <div className="about_container_content2">
          <img
            className="about_container_image"
            src={developerA}
            alt="developer_image"
          />
        </div>
      </div>
      <div className="about_quote">
        “SEEK INSPIRATION IN THE PROCESS, BE QUICK TO FIND BEAUTY IN ALL THINGS,
        AND ALWAYS ENJOY THE JOURNEY.”
        <div className="quote_author">- J.K. Winders</div>
      </div>
      <SnackBar />
      <Contact />
    </div>
  );
};

export default About;
