import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ref, push, set, remove, update } from "firebase/database";
import { database } from "../../firebase";
import { updateTodo, moveTodo, removeTodo } from "../../models/models";
import { showSnackBar } from "../../redux/snackBar/snackBarSlice";
import moment from "moment";

const initialState: any = {};

const nameSpace: string = "todoApp";

const writeNewToDo = createAsyncThunk<void, string>(
  `${nameSpace}/writeNewToDo`,
  async (text: string) => {
    set(
      push(
        ref(
          database,
          `/TodoApp/${JSON.parse(localStorage.getItem("userId")!)}/toDo`
        )
      ),
      {
        createdAt: moment().format(),
        text,
      }
    );
  }
);

const writeNewOnProgress = createAsyncThunk<void, string>(
  `${nameSpace}/writeNewOnProgress`,
  async (text: string) => {
    set(
      push(
        ref(
          database,
          `/TodoApp/${JSON.parse(localStorage.getItem("userId")!)}/onProgress`
        )
      ),
      {
        createdAt: moment().format(),
        text,
      }
    );
  }
);

const writeNewCompleted = createAsyncThunk<void, string>(
  `${nameSpace}/writeNewCompleted`,
  async (text: string) => {
    set(
      push(
        ref(
          database,
          `/TodoApp/${JSON.parse(localStorage.getItem("userId")!)}/completed`
        )
      ),
      {
        createdAt: moment().format(),
        text,
      }
    );
  }
);

const updateToDo = createAsyncThunk<void, updateTodo>(
  `${nameSpace}/updateToDo`,
  async (params: updateTodo) => {
    update(
      ref(
        database,
        `/TodoApp/${JSON.parse(localStorage.getItem("userId")!)}/toDo/${
          params.id
        }`
      ),
      {
        createdAt: params.createdAt,
        text: params.text,
      }
    );
  }
);

const updateOnProgress = createAsyncThunk<void, updateTodo>(
  `${nameSpace}/updateOnProgress`,
  async (params: updateTodo) => {
    update(
      ref(
        database,
        `/TodoApp/${JSON.parse(localStorage.getItem("userId")!)}/onProgress/${
          params.id
        }`
      ),
      {
        createdAt: params.createdAt,
        text: params.text,
      }
    );
  }
);

const updateCompleted = createAsyncThunk<void, updateTodo>(
  `${nameSpace}/updateCompleted`,
  async (params: updateTodo) => {
    update(
      ref(
        database,
        `/TodoApp/${JSON.parse(localStorage.getItem("userId")!)}/completed/${
          params.id
        }`
      ),
      {
        createdAt: params.createdAt,
        text: params.text,
      }
    );
  }
);

const deleteToDo = createAsyncThunk<void, string>(
  `${nameSpace}/deleteToDo`,
  async (id: string, ThunkAPI) => {
    remove(
      ref(
        database,
        `/TodoApp/${JSON.parse(localStorage.getItem("userId")!)}/toDo/${id}`
      )
    )
      .then(() => {
        ThunkAPI.dispatch(
          showSnackBar({
            text: "Deleted SuccessFully",
            type: "success",
          })
        );
      })
      .catch(() => {
        ThunkAPI.dispatch(
          showSnackBar({
            text: "Deleting Failed",
            type: "error",
          })
        );
      });
  }
);

const deleteOnProgress = createAsyncThunk<void, string>(
  `${nameSpace}/deleteOnProgress`,
  async (id: string, ThunkAPI) => {
    remove(
      ref(
        database,
        `/TodoApp/${JSON.parse(
          localStorage.getItem("userId")!
        )}/onProgress/${id}`
      )
    )
      .then(() => {
        ThunkAPI.dispatch(
          showSnackBar({
            text: "Deleted SuccessFully",
            type: "success",
          })
        );
      })
      .catch(() => {
        ThunkAPI.dispatch(
          showSnackBar({
            text: "Deleting Failed",
            type: "error",
          })
        );
      });
  }
);

const deleteCompleted = createAsyncThunk<void, string>(
  `${nameSpace}/deleteCompleted`,
  async (id: string, ThunkAPI) => {
    remove(
      ref(
        database,
        `/TodoApp/${JSON.parse(
          localStorage.getItem("userId")!
        )}/completed/${id}`
      )
    )
      .then(() => {
        ThunkAPI.dispatch(
          showSnackBar({
            text: "Deleted SuccessFully",
            type: "success",
          })
        );
      })
      .catch(() => {
        ThunkAPI.dispatch(
          showSnackBar({
            text: "Deleting Failed",
            type: "error",
          })
        );
      });
  }
);

const moveToDoCard = createAsyncThunk<void, moveTodo>(
  `${nameSpace}/moveToDoCard`,
  async (params: moveTodo) => {
    if (Object.keys(params.movingData).includes("createdAt")) {
      set(
        push(
          ref(
            database,
            `/TodoApp/${JSON.parse(localStorage.getItem("userId")!)}/${
              params.moveTo
            }`
          )
        ),
        params.movingData
      );
    } else {
      remove(
        ref(
          database,
          `/TodoApp/${JSON.parse(localStorage.getItem("userId")!)}/${
            params.moveTo
          }`
        )
      );
      for (let i = 0; i < params.movingData.length; i++) {
        set(
          push(
            ref(
              database,
              `/TodoApp/${JSON.parse(localStorage.getItem("userId")!)}/${
                params.moveTo
              }`
            )
          ),
          {
            createdAt: params.movingData[i][1].createdAt,
            text: params.movingData[i][1].text,
          }
        );
      }
    }
  }
);

const removeToDoCard = createAsyncThunk<void, removeTodo>(
  `${nameSpace}/moveToDoCard`,
  async (params: removeTodo) => {
    remove(
      ref(
        database,
        `/TodoApp/${JSON.parse(localStorage.getItem("userId")!)}/${
          params.removeFrom
        }/${params.removeId}`
      )
    );
  }
);

export const todoAppSlice = createSlice({
  name: nameSpace,
  initialState,
  reducers: {
    populateTodoData: (state, action: PayloadAction<any>) =>
      (state = action.payload),
  },
});

export const { populateTodoData } = todoAppSlice.actions;
export {
  writeNewToDo,
  writeNewOnProgress,
  writeNewCompleted,
  updateToDo,
  updateOnProgress,
  updateCompleted,
  deleteToDo,
  deleteOnProgress,
  deleteCompleted,
  moveToDoCard,
  removeToDoCard,
};
export default todoAppSlice.reducer;
