import { createSlice } from "@reduxjs/toolkit";

const initialState: boolean = false;

const nameSpace: string = "contact";

export const contact = createSlice({
  name: nameSpace,
  initialState,
  reducers: {
    showContact: (state) => (state = true),
    hideContact: (state) => (state = false),
  },
});

export const { showContact, hideContact } = contact.actions;
export default contact.reducer;
