import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import SocialMedia from "../../components/SocialMedia";
import { TextareaAutosize, TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import "./index.scss";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { showSnackBar } from "../../redux/snackBar/snackBarSlice";
import { EmailValidator } from "../../utils";
import { hideContact } from "../../redux/contact/contactSlice";

const Contact: React.FC = () => {
  const openModal = useAppSelector((state) => state.contact);
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<boolean>(false);
  const [loading, setloading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const form = React.useRef() as React.MutableRefObject<HTMLFormElement>;

  const sendEmail = (e: React.FormEvent) => {
    e.preventDefault();
    setloading(true);
    if (!EmailValidator(email)) {
      dispatch(
        showSnackBar({ text: "Please put a valid email", type: "error" })
      );
      setloading(false);
      setEmailError(true);
      setTimeout(() => {
        setEmailError(false);
      }, 2000);
      return;
    }

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID!,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID!,
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY!
      )
      .then(
        (result) => {
          setloading(false);
          dispatch(hideContact());
          dispatch(showSnackBar({ text: "Email Sent", type: "success" }));
        },
        (error) => {
          console.log(error);
          setloading(false);
          dispatch(
            showSnackBar({ text: "Email failed to send", type: "error" })
          );
        }
      );
  };

  return (
    <Modal open={openModal} onClose={() => dispatch(hideContact())}>
      <div className="contact_me_form">
        <form ref={form} className="contact_me_container" onSubmit={sendEmail}>
          <div className="contact_me_header">GET IN TOUCH</div>
          <TextField
            // autoFocus={window.innerWidth > 1000}
            required
            type="text"
            name="user_name"
            id="outlined-required"
            label="Full Name"
            sx={{ width: "100%" }}
            inputProps={{ maxLength: 25 }}
          />
          <TextField
            type="email"
            name="user_email"
            required
            label="Email"
            variant="outlined"
            error={emailError}
            sx={{ width: "100%" }}
            inputProps={{ maxLength: 30 }}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextareaAutosize
            className="contact_me_text_area"
            name="message"
            minRows={3}
            placeholder="Your Message..."
            // @ts-ignore
            maxLength="400"
          />

          {loading ? (
            <CircularProgress />
          ) : (
            <button
              disabled={loading}
              type="submit"
              className="contact_me_button"
            >
              SEND EMAIL{" "}
            </button>
          )}
          <SocialMedia />
          <CloseIcon
            className="contact_me_close_button"
            sx={{ fontSize: "3rem" }}
            onClick={() => {
              setEmailError(false);
              dispatch(hideContact());
            }}
          />
        </form>
      </div>
    </Modal>
  );
};

export default Contact;
