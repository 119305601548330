import "./index.scss";
import Hamburger from "../Hamburger";
import SideBar from "../SideBar";
import { useAppDispatch } from "../../redux/hooks";
import { showContact } from "../../redux/contact/contactSlice";
import { useNavigate } from "react-router-dom";
import signWhite from "../../assets/svgs/signWhite.svg";
// import useAuth from "../../customHooks/useAuth";
// import CircularProgress from "@mui/material/CircularProgress";
// import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";

const Header: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // const { currentUser, loading, handleLogout } = useAuth();

  return (
    <div
      className="header_container"
      style={{
        backgroundColor: `${
          window.location.pathname === "/" ||
          window.location.pathname.includes("/home")
            ? "transparent"
            : "#171219"
        }`,
        height: `${
          window.location.pathname === "/" ||
          window.location.pathname.includes("home")
            ? "6rem"
            : ""
        }`,
      }}
    >
      <div
        className="signature_container"
        onClick={() => {
          if (
            window.location.pathname === "/" ||
            window.location.pathname.includes("home")
          ) {
            window.location.reload();
          } else {
            navigate("/");
          }
        }}
      >
        <img className="sign" src={signWhite} alt="sign" />
      </div>
      <div className="links_container">
        <div
          onClick={() => {
            navigate("/projects");
          }}
        >
          PROJECTS
        </div>
        <div
          onClick={() => {
            navigate("/gallery");
          }}
        >
          GALLERY
        </div>
        <div onClick={() => dispatch(showContact())}>CONTACT</div>

        {/* {!loading && currentUser ? (
          <div
            onClick={() => {
              handleLogout();
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            LOG <ExitToAppRoundedIcon />
          </div>
        ) : !currentUser && !loading ? (
          <div
            onClick={() => {
              navigate("/login");
            }}
          >
            LOG-IN
          </div>
        ) : (
          <CircularProgress sx={{ color: "rgb(157, 157, 157)" }} />
        )} */}
      </div>
      <div className="links_container_hamburger">
        <Hamburger />
        <SideBar />
      </div>
    </div>
  );
};

export default Header;
