import React from "react";
import "./index.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import posi from "../../assets/images/posi.png";
import novai from "../../assets/images/novai.png";
import CSRi from "../../assets/images/CSRi.png";
import raffeei from "../../assets/images/raffeei.png";
import memoryGame from "../../assets/images/memoryGame.jpg";
import budgetApp from "../../assets/images/budgetApp.jpg";
import gallery from "../../assets/images/gallery.jpg";
import { useNavigate } from "react-router-dom";
import techdeni from "../../assets/images/techdeni.jpg";

const ProjectsCarousel: React.FC = () => {
  const navigate = useNavigate();

  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500, //animation speed when sliding
    autoplaySpeed: 5000, //Delay between each auto scroll
    // pauseOnFocus: false, //Prevents autoplay while focused on slides
    swipeToSlide: true, //Enable drag/swipe irrespective of `slidesToScroll`
    // pauseOnHover: false,
    // prevArrow: <SamplePrevArrow />,
    // nextArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 1450,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1160,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          // initialSlide: 1,
        },
      },
    ],
  };

  return (
    <div className="projects_carousel_container">
      <div className="projects_carousel_items">
        <Slider {...settings} className="projects_carousel_slider">
          <div className="projects_carousel_item">
            <div className="projects_carousel_item_image_container">
              <img
                src={posi}
                alt="todoApp"
                className="projects_carousel_item_image"
              />
            </div>
            <div className="projects_carousel_item_title">All-in-One POS</div>
            <div className="projects_carousel_item_description">
              An all-encompassing restaurant POS system, seamlessly managing
              orders for delivery, online, dine-in, and takeaway. Beyond
              advanced filters and handling payment it includes bill printing,
              notifications, KOT, order editing, shift management, analytics and
              more. It's the ultimate solution for versatile and efficient
              point-of-sale management. Made using :
              <br />
              <span className="projects_carousel_item_made_with">
                React/Typescript/Redux/Tailwind/Material UI/SOCKET
              </span>
            </div>

            <div className="projects_carousel_item_button_container">
              <div className="projects_carousel_item_button"> View Code</div>
              <div className="projects_carousel_item_button">show Demo</div>
            </div>
          </div>
          <div className="projects_carousel_item">
            <div className="projects_carousel_item_image_container">
              <img
                src={novai}
                alt="todoApp"
                className="projects_carousel_item_image"
              />
            </div>
            <div className="projects_carousel_item_title">
              Food Delivery SITE
            </div>
            <div className="projects_carousel_item_description">
              A comprehensive food delivery website, offering a user-friendly
              interface for ordering, cart management and more. Alongside, a
              versatile dashboard which allows for manual order processing,
              orders and sales analytics, reviews, change menu, shift
              management, printing bills, notifications, KOT (Kitchen Order
              Tickets) and many more. Made using :
              <br />
              <span className="projects_carousel_item_made_with">
                React/Material UI/CSS/SOCKET
              </span>
            </div>

            <div className="projects_carousel_item_button_container">
              <div className="projects_carousel_item_button"> View Code</div>
              <div className="projects_carousel_item_button">show Demo</div>
            </div>
          </div>
          <div className="projects_carousel_item">
            <div className="projects_carousel_item_image_container">
              <img
                src={raffeei}
                alt="todoApp"
                className="projects_carousel_item_image"
              />
            </div>
            <div className="projects_carousel_item_title">E-COMMERCE SITE</div>
            <div className="projects_carousel_item_description">
              This is a go-to solution for seamless online shopping with a
              responsive website and an intuitive dashboard, enjoy features like
              search, efficient filters, and a user-friendly cart system—all
              seamlessly integrated for a simplified experience in one
              comprehensive platform. Made using :
              <br />
              <span className="projects_carousel_item_made_with">
                Next JS/Typescript/Redux Toolkit/CSS
              </span>
            </div>

            <div className="projects_carousel_item_button_container">
              <div className="projects_carousel_item_button">View Code</div>
              <div className="projects_carousel_item_button">show Demo</div>
            </div>
          </div>
          <div className="projects_carousel_item">
            <div className="projects_carousel_item_image_container">
              <img
                src={CSRi}
                alt="todoApp"
                className="projects_carousel_item_image"
              />
            </div>
            <div className="projects_carousel_item_title">POS</div>
            <div className="projects_carousel_item_description">
              A hotel management POS simplifying order handling from websites
              and call-in orders. Manage order creation, editing, and rider
              assignments effortlessly, streamlining the workflow. Track orders
              with ease and simplify the entire process, ensuring successful
              deliveries. Made using :
              <br />
              <span className="projects_carousel_item_made_with">
                React/Typescript/Material UI/CSS/SOCKET
              </span>
            </div>

            <div className="projects_carousel_item_button_container">
              <div className="projects_carousel_item_button"> View Code</div>
              <div className="projects_carousel_item_button">show Demo</div>
            </div>
          </div>
          <div className="projects_carousel_item">
            <div className="projects_carousel_item_image_container">
              <img
                src={techdeni}
                alt="techDen"
                className="projects_carousel_item_image"
              />
            </div>
            <div className="projects_carousel_item_title">E-commerce site</div>
            <div className="projects_carousel_item_description">
              This tech e-commerce website offers a user-friendly interface with
              responsive design for a seamless shopping experience. Features
              includes search and filtering, authentication and add to cart,
              etc. Made using :
              <br />
              <span className="projects_carousel_item_made_with">
                React/Typescript/Firebase/Redux/SCSS/Material UI
              </span>
            </div>
            <div className="projects_carousel_item_button_container">
              <a
                href="https://github.com/Prabin-Thakur/techden"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <div className="projects_carousel_item_button"> View Code</div>
              </a>
              <a
                href="https://tech-den.netlify.app/"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <div className="projects_carousel_item_button">show Demo</div>
              </a>
            </div>
          </div>
          <div className="projects_carousel_item">
            <div className="projects_carousel_item_image_container">
              <img
                src={gallery}
                alt="todoApp"
                className="projects_carousel_item_image"
              />
            </div>
            <div className="projects_carousel_item_title">Gallery</div>
            <div className="projects_carousel_item_description">
              A simple gallery app that enables users to view and interact with
              a collection of photos. This app features a responsive design,
              allowing it to adapt seamlessly to different screen sizes and
              devices. Users can easily interact with them by clicking, zooming,
              or swiping. Made using :
              <br />
              <span className="projects_carousel_item_made_with">
                React/Firebase/Typescript/SCSS
              </span>
            </div>
            <div className="projects_carousel_item_button_container">
              <div className="projects_carousel_item_button"> View Code</div>
              <div
                className="projects_carousel_item_button"
                onClick={() => navigate("/gallery")}
              >
                show Demo
              </div>
            </div>
          </div>
          <div className="projects_carousel_item">
            <div className="projects_carousel_item_image_container">
              <img
                src={memoryGame}
                alt="todoApp"
                className="projects_carousel_item_image"
              />
            </div>
            <div className="projects_carousel_item_title">Memory Game</div>
            <div className="projects_carousel_item_description">
              A simple memory game built with pure JS is a fun and engaging way
              for users to test their memory skills. This game has beautiful
              design which also features a grid of cards that users can flip
              over to reveal images and enjoy as they play along. Made using :
              <br />
              <span className="projects_carousel_item_made_with">
                HTLML/CSS/Javascript
              </span>
            </div>
            <div className="projects_carousel_item_button_container">
              <a
                href="https://github.com/Prabin-Thakur/Memory-Game"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <div className="projects_carousel_item_button"> View Code</div>
              </a>
              <a
                href="https://memoory-game.netlify.app/"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <div className="projects_carousel_item_button">show Demo</div>
              </a>
            </div>
          </div>
          <div className="projects_carousel_item">
            <div className="projects_carousel_item_image_container">
              <img
                src={budgetApp}
                alt="todoApp"
                className="projects_carousel_item_image"
              />
            </div>
            <div className="projects_carousel_item_title">Budget App</div>
            <div className="projects_carousel_item_description">
              A simple budget app that allows users to easily manage their
              finances by tracking their budgets and expenses. This app features
              a user-friendly interface and design enabling users to track their
              spending in detail. Made using :
              <br />
              <span className="projects_carousel_item_made_with">
                React/Bootstrap
              </span>
            </div>
            <div className="projects_carousel_item_button_container">
              <a
                href="https://github.com/Prabin-Thakur/Budget-App"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <div className="projects_carousel_item_button"> View Code</div>
              </a>
              <a
                href="https://budgeet-app.netlify.app/"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <div className="projects_carousel_item_button">show Demo</div>
              </a>
            </div>
          </div>
        </Slider>
      </div>
      <div
        className="home_about_button"
        style={{ marginTop: "0px" }}
        onClick={() => navigate("/projects")}
      >
        VIEW ALL
      </div>
    </div>
  );
};

export default ProjectsCarousel;
