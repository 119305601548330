import { useState } from "react";
import "./index.scss";
import Header from "../../components/Header";
import SocialMedia from "../../components/SocialMedia";
import Footer from "../../components/Footer";
import Contact from "../../components/Contact";
import SnackBar from "../../components/SnackBar";
import GalleryCarousel from "../../components/GalleryCarousel";
import { useNavigate } from "react-router-dom";
import ProjectsCarousel from "../../components/ProjectsCarousel";
import Avatar from "@mui/material/Avatar";
import developer from "../../assets/images/developer.jpg";
import fall from "../../assets/images/Fall.jpg";

const Home: React.FC = () => {
  const [animationEnded, setAnimationEnded] = useState<boolean>(false);
  const [showNameAnimation, setShowNameAnimation] = useState<boolean>(false);
  const navigate = useNavigate();
  // const [scrollPosition, setScrollPosition] = useState<number>(0);

  // useEffect(() => {
  //   function handleScroll() {
  //     setScrollPosition(window.scrollY);
  //   }
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  // //Adjust the *-0.5 value to change the speed of the background image movement.
  // const backgroundPosition = `center ${scrollPosition * -0.5}px`;

  function handleAnimationEnd() {
    setAnimationEnded(true);
  }

  return (
    <div className="home_container">
      <div className="home_introduction_container">
        <div className="home_introduction_header">
          <Header />
        </div>
        <img
          onLoad={() => setShowNameAnimation(true)}
          src={fall}
          alt="fall"
          className="home_introduction_image"
        />
        {showNameAnimation && (
          <div className="home_introduction_description">
            <div
              className="home_introduction_developer"
              onAnimationEnd={handleAnimationEnd}
              style={{
                borderRight: animationEnded
                  ? "none"
                  : "0.15em solid rgb(254, 198, 56)",
              }}
            >
              Hey, I'm Prabin
            </div>
            <div className="home_introduction_dive">Dive in with me...</div>
          </div>
        )}
      </div>
      <div className="home_about_container">
        <Avatar
          alt="developer"
          src={developer}
          sx={{ width: 184, height: 184 }}
          className="home_about_image"
        />
        <div className="home_about_item1">
          <p className="home_about_title">ABOUT</p>
          <p className="home_about_description">
            Am an artist and a front-end developer with a passion for
            storytelling, technology and all. When I'm not busy coding or
            ruminating or capturing moments through my eyes or lens, you can
            find me exploring the oblivion. Want to know more? tag along!
          </p>
          <p className="home_about_button" onClick={() => navigate("/about")}>
            LEARN MORE ABOUT ME
          </p>
        </div>
      </div>
      <GalleryCarousel />
      <p className="home_projects_carousel_container_title">Projects</p>
      <ProjectsCarousel />
      <div className="follow_container">
        <p>Follow</p>
        <SocialMedia />
      </div>
      <Footer />
      <Contact />
      <SnackBar />
    </div>
  );
};

export default Home;
