import { Link } from "react-router-dom";
import notFound from "../../assets/svgs/notFound.svg";
import SnackBar from "../../components/SnackBar";
import Header from "../../components/Header";
import Contact from "../../components/Contact";
import "./index.scss";

const Missing: React.FC = () => {
  return (
    <>
      <Header />
      <div className="container">
        <div className="notFound">
          <img src={notFound} alt="404notFound" />
        </div>
        <Link to="/" className="go_back_to_home">
          Visit Homepage
        </Link>
        <SnackBar />
        <Contact />
      </div>
    </>
  );
};

export default Missing;
