import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import snackBarReducer from "./snackBar/snackBarSlice";
import sideBarReducer from "./sideBar/sideBarSlice";
import todoAppReducer from "./todoApp/todoAppSlice";
import galleryReducer from "./gallery/gallerySlice";
import contactReducer from "./contact/contactSlice";

export const store = configureStore({
  reducer: {
    snackBar: snackBarReducer,
    sideBar: sideBarReducer,
    todoApp: todoAppReducer,
    gallery: galleryReducer,
    contact: contactReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
