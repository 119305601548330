import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fireStoreDatabase, storage } from "../../firebase";
import { showSnackBar } from "../snackBar/snackBarSlice";
import { collection, addDoc } from "firebase/firestore";
import { galleryData, galleryParamsUploading } from "../../models/models";
import { ref, deleteObject } from "firebase/storage";

const initialState: any[] = [];

const nameSpace: string = "gallery";

const uploadImageData = createAsyncThunk<void, galleryParamsUploading>(
  `${nameSpace}/gallery`,
  async (params: galleryParamsUploading, thunkAPI) => {
    try {
      await addDoc(collection(fireStoreDatabase, "gallery"), {
        title: params.title,
        story: params.story,
        takenFrom: params.takenFrom,
        model: params.model,
        imageName: params.imageName,
        image: params.image,
      });
      thunkAPI.dispatch(
        showSnackBar({ text: "Image successfully uploaded", type: "success" })
      );
    } catch (err) {
      console.log(err);
      thunkAPI.dispatch(
        showSnackBar({ text: "Image failed to upload", type: "error" })
      );
    }
  }
);

const deleteImage = createAsyncThunk<void, string>(
  `${nameSpace}/gallery`,
  async (name: string, thunkAPI) => {
    const deleteRef = ref(storage, name);

    deleteObject(deleteRef)
      .then(() => {
        thunkAPI.dispatch(
          showSnackBar({ text: "Image successfully deleted", type: "success" })
        );
      })
      .catch((err) => {
        thunkAPI.dispatch(
          showSnackBar({ text: "Image failed to delete", type: "error" })
        );
        console.log(err);
      });
  }
);

export const gallerySlice = createSlice({
  name: nameSpace,
  initialState,
  reducers: {
    populateGallery: (state, action: PayloadAction<galleryData[]>) =>
      (state = action.payload),
    deletefromGallery: (state, action: PayloadAction<string>) =>
      (state = state.filter((image) => image.id !== action.payload)),
  },
});

export const { populateGallery, deletefromGallery } = gallerySlice.actions;
export { uploadImageData, deleteImage };
export default gallerySlice.reducer;
