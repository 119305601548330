import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import TextCard from "../TextCard";
import AddIcon from "@mui/icons-material/Add";
import { useAppDispatch } from "../../redux/hooks";
import {
  writeNewToDo,
  writeNewOnProgress,
  writeNewCompleted,
} from "../../redux/todoApp/todoAppSlice";
import { TodoCardProps } from "../../models/models";
import { Droppable } from "react-beautiful-dnd";
import { ArrayToDoValue } from "../../models/models";
// import Backdrop from "@mui/material/Backdrop";
import "./index.scss";
import { showSnackBar } from "../../redux/snackBar/snackBarSlice";

const ToDoCard: React.FC<TodoCardProps> = React.memo(
  ({ title, data, droppableId }) => {
    const [newCard, setNewCard] = useState<boolean>(false);
    const saveRef = useRef<HTMLButtonElement>(null);
    const textAreaDivRef = useRef<HTMLDivElement>(null);
    const [currentNewToDo, setCurrentNewToDo] = useState<string>("");
    // const [backDropOpener, setBackDropOpener] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    // useEffect(() => {
    //   if (newCard) {
    //     setBackDropOpener(true);
    //   }
    //   if (!setNewCard) {
    //     setBackDropOpener(false);
    //   }
    // }, [newCard, setNewCard]);

    useEffect(() => {
      let handler = (event: any) => {
        if (
          !saveRef.current?.contains(event.target) &&
          !textAreaDivRef.current?.contains(event.target)
        ) {
          setNewCard(false);
          setCurrentNewToDo("");
        }
      };

      document.addEventListener("mousedown", handler);

      return () => {
        document.removeEventListener("mousedown", handler);
      };
    }, [saveRef, textAreaDivRef]);

    const handleWritingNewTodo = (): void => {
      if (
        currentNewToDo.length > 0 &&
        currentNewToDo.replace(/ /g, "").length !== 0
      ) {
        if (title === "To Do") {
          if (data.length >= 10) {
            dispatch(
              showSnackBar({
                text: "Max todo items reached",
                type: "error",
              })
            );
            setNewCard(false);
            setCurrentNewToDo("");
          } else {
            dispatch(writeNewToDo(currentNewToDo));
            setNewCard(false);
            setCurrentNewToDo("");
          }
        }
        if (title === "On Progress") {
          if (data.length >= 10) {
            dispatch(
              showSnackBar({
                text: "Max todo items reached",
                type: "error",
              })
            );
            setNewCard(false);
            setCurrentNewToDo("");
          } else {
            dispatch(writeNewOnProgress(currentNewToDo));
            setNewCard(false);
            setCurrentNewToDo("");
          }
        }
        if (title === "Completed") {
          if (data.length >= 10) {
            dispatch(
              showSnackBar({
                text: "Max todo items reached",
                type: "error",
              })
            );
            setNewCard(false);
            setCurrentNewToDo("");
          } else {
            dispatch(writeNewCompleted(currentNewToDo));
            setNewCard(false);
            setCurrentNewToDo("");
          }
        }
      } else {
        setNewCard(false);
        setCurrentNewToDo("");
      }
    };

    return (
      <Droppable droppableId={droppableId}>
        {(provided, snapshot) => (
          <div
            className={`ToDO_card_container ${
              snapshot.isDraggingOver ? "dragging-over" : ""
            }`}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <div className="ToDO_card_title">{title}</div>
            {data &&
              data.map((item: ArrayToDoValue, index: number) => (
                <TextCard
                  index={index}
                  title={title}
                  data={item}
                  key={item[0]}
                />
              ))}
            {provided.placeholder}

            {newCard ? (
              <>
                {/* <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={backDropOpener}
                > */}
                <div ref={textAreaDivRef}>
                  <TextareaAutosize
                    className="text_area"
                    minRows={3}
                    placeholder="Enter a title for this card..."
                    autoFocus
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        handleWritingNewTodo();
                      }
                    }}
                    onChange={(e) => setCurrentNewToDo(e.target.value)}
                    // @ts-ignore
                    maxLength="120"
                  />
                </div>
                <div className="new_card_checker">
                  <button
                    className="new_card_save_button"
                    ref={saveRef}
                    onClick={() => handleWritingNewTodo()}
                  >
                    Save
                  </button>
                  <CloseIcon
                    onClick={() => {
                      setNewCard(false);
                      setCurrentNewToDo("");
                    }}
                    className="new_card_cancel_button"
                  />
                </div>
                {/* </Backdrop> */}
              </>
            ) : (
              <div
                onClick={() => {
                  setNewCard(true);
                }}
                className="add_card_icon"
              >
                <AddIcon /> &nbsp;Add another card
              </div>
            )}
          </div>
        )}
      </Droppable>
    );
  }
);

export default ToDoCard;
