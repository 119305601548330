import { Route } from "./models/models";
import LoginPage from "./views/LoginPage";
import About from "./views/About";
import TodoApp from "./views/TodoApp";
import Gallery from "./views/Gallery";
import Home from "./views/Home";
import Projects from "./views/Projects";

const routes: Route[] = [
  {
    exact: true,
    path: "/",
    component: <Home />,
  },
  {
    path: "/about",
    component: <About />,
  },
  {
    path: "/home",
    component: <Home />,
  },
  {
    path: "/gallery",
    component: <Gallery />,
  },
  {
    path: "/projects",
    component: <Projects />,
  },
];

const loggedRoutes: Route[] = [
  {
    path: "/todoApp",
    component: <TodoApp />,
  },
];

const protectedRoutes: Route[] = [
  {
    path: "/login",
    component: (
      <LoginPage
        bigText="Log in to Account"
        buttonText="Log In"
        googlebuttonText="Log in with"
        linkBeforeText="Don't have an account ?"
        linkText="Sign Up"
      />
    ),
  },
  {
    path: "/signup",
    component: (
      <LoginPage
        bigText="Sign in to Account"
        buttonText="Create Account"
        googlebuttonText="Sign up with"
        linkBeforeText="Have an account ?"
        linkText="Log In"
      />
    ),
  },
];

export { routes, protectedRoutes, loggedRoutes };
