import { useEffect, useState } from "react";
import { useAppDispatch } from "../redux/hooks";
import { ref, onValue } from "firebase/database";
import { database } from "../firebase";
import { populateTodoData } from "../redux/todoApp/todoAppSlice";

const Intialize = () => {
  const dispatch = useAppDispatch();
  const [todoAppData, setTodoAppData] = useState<any>({});
  const logged: string | null = JSON.parse(localStorage.getItem("isLoggedIn")!);
  const userId: string | null = JSON.parse(localStorage.getItem("userId")!);

  useEffect(() => {
    if (logged === null) {
      window.localStorage.setItem("isLoggedIn", JSON.stringify(false));
    }
    if (userId !== null) {
      window.localStorage.setItem("isLoggedIn", JSON.stringify(true));
    }
  }, [logged, userId]);

  useEffect(() => {
    if (userId !== null) {
      onValue(
        ref(
          database,
          `/TodoApp/${JSON.parse(localStorage.getItem("userId")!)}`
        ),
        (snapshot) => {
          const fetchedData = snapshot.val();
          if (fetchedData !== null) {
            setTodoAppData(fetchedData);
          } else {
            dispatch(populateTodoData({}));
          }
        }
      );
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (Object.keys(todoAppData).length !== 0) {
      dispatch(populateTodoData(todoAppData));
    }
  }, [dispatch, todoAppData]);
};

export default Intialize;
