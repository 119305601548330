import { useMediaQuery } from "@mui/material";
import { validate } from "email-validator";

//takes parameter email and returns boolean checking validation
const EmailValidator = (value: string): boolean => {
  return validate(value);
};

//returns string of error message according to firebase error code
const mapAuthCodeToMessage = (error: any): string => {
  switch (error.code) {
    case "auth/invalid-password":
      return "Password provided is not correct";

    case "auth/invalid-email":
      return "Email provided is invalid";

    case "auth/email-already-in-use":
      return "Email Already in use";

    case "auth/wrong-password":
      return "Password is not correct";

    case "auth/user-not-found":
      return "User not Found";

    default:
      return "";
  }
};

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

export { EmailValidator, mapAuthCodeToMessage, scrollToTop };

export const useColumns = () => {
  const isSmallScreen = useMediaQuery("(max-width: 500px)");
  const isMediumScreen = useMediaQuery("(max-width: 900px)");
  const isLargeScreen = useMediaQuery("(max-width: 1200px)");
  const isExtraLargeScreen = useMediaQuery("(min-width: 1200px)");

  if (isSmallScreen) return 1;
  if (isMediumScreen) return 2;
  if (isLargeScreen) return 3;
  if (isExtraLargeScreen) return 4;
};
