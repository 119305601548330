import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { snackBarState, snackBarParams } from "../../models/models";

const initialState: snackBarState = {
  showing: false,
  text: "",
  type: "success",
};

const nameSpace: string = "snackBar";

//first parameter to tell type that should be returned
//second paramter to tell type of argument it accepts
//third parameter is an object with : {dispatch?, state?, extra?, rejectvalue?} fields
//       extra is useful when you need to pass some static data to request function like JSON web token or HTTP Headers
//       rejectValue is useful when we need to type possible errors
// const fireSnackBar = createAsyncThunk<void, snackBarParams>(
//   `${nameSpace}/fireSnackBar`,
//   async (params: snackBarParams, thunkAPI) => {
//     thunkAPI.dispatch(showSnackBar(params));
//     setTimeout(() => {
//       thunkAPI.dispatch(hideSnackBar());
//     }, 2000);
//   }
// );

export const snackBarSlice = createSlice({
  //name
  name: nameSpace,
  //intialState
  initialState,
  //all reducerLogic inside this reducers object
  reducers: {
    //define actiontype and its logic like this second parameter PayloadAction defines paramater type
    showSnackBar: (state, action: PayloadAction<snackBarParams>) =>
      (state = {
        showing: true,
        text: action.payload.text,
        type: action.payload.type,
      }),
    hideSnackBar: (state) =>
      (state = { showing: false, text: "", type: "success" }),
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(fireSnackBar.pending, (state) => {})
  //     .addCase(fireSnackBar.fulfilled, (state, { payload }) => {})
  //     .addCase(fireSnackBar.rejected, (state, { payload }) => {});
  // },
});

export const { showSnackBar, hideSnackBar } = snackBarSlice.actions;
// export { fireSnackBar };
export default snackBarSlice.reducer;
