import "./index.scss";
import { useAppDispatch } from "../../redux/hooks";
import { showContact } from "../../redux/contact/contactSlice";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import { scrollToTop } from "../../utils";

const Footer: React.FC = () => {
  const dispatch = useAppDispatch();

  return (
    <div className="footer_container">
      <div className="contact_me" onClick={() => dispatch(showContact())}>
        CONTACT ME
      </div>
      <div className="back_to_top" onClick={scrollToTop}>
        <p>BACK TO TOP</p>
        <KeyboardBackspaceRoundedIcon
          className="back_to_top_icon"
          sx={{ fontSize: "2.5rem" }}
        />
      </div>
    </div>
  );
};

export default Footer;
