import React, { useRef, useEffect, useState, useCallback } from "react";
import "./index.scss";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import CircularProgress from "@mui/material/CircularProgress";
import useAuth from "../../customHooks/useAuth";
import {
  deleteImage,
  deletefromGallery,
} from "../../redux/gallery/gallerySlice";
import { doc, deleteDoc } from "firebase/firestore";
import { fireStoreDatabase } from "../../firebase";
import { useAppDispatch } from "../../redux/hooks";
import { showSnackBar } from "../../redux/snackBar/snackBarSlice";
import { galleryData } from "../../models/models";
// import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

interface imageViewerProps {
  gallery: galleryData[] | [];
  imageViewer: boolean;
  setImageViewer: React.Dispatch<React.SetStateAction<boolean>>;
  imageViewerSrc: string;
  setImageViewerSrc: React.Dispatch<React.SetStateAction<string>>;
  currentImageIndex: number;
  setCurrentImageIndex: React.Dispatch<React.SetStateAction<number>>;
  currentImageId: string;
  setCurrentImageId: React.Dispatch<React.SetStateAction<string>>;
  currentImageName: string;
  setCurrentImageName: React.Dispatch<React.SetStateAction<string>>;
  fetchMoreImages: any;
}

const ImageViewer: React.FC<imageViewerProps> = React.memo(
  ({
    gallery,
    imageViewer,
    setImageViewer,
    imageViewerSrc,
    setImageViewerSrc,
    currentImageIndex,
    setCurrentImageIndex,
    currentImageId,
    setCurrentImageId,
    currentImageName,
    setCurrentImageName,
    fetchMoreImages,
  }) => {
    const { currentUser } = useAuth();
    const dispatch = useAppDispatch();
    const imageRef = useRef<HTMLImageElement>(null);
    const deleteRef = useRef<SVGSVGElement>(null);
    const nextIconRef = useRef<SVGSVGElement>(null);
    const previousIconRef = useRef<SVGSVGElement>(null);
    const [imageDeleting, setImageDeleting] = useState<boolean>(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    //closing imageViewer
    const handleImageViewerClose = useCallback(() => {
      setImageViewer(false);
      setImageViewerSrc("");
      setCurrentImageId("");
      setCurrentImageIndex(0);
      setCurrentImageName("");
      setIsLoaded(false);
    }, [
      setImageViewer,
      setImageViewerSrc,
      setCurrentImageId,
      setCurrentImageName,
      setCurrentImageIndex,
    ]);

    //to hide scrollbar when the imageViewer is on
    useEffect(() => {
      // Add or remove the style on mount/unmount and when `isOn` changes
      document.body.style.overflow = imageViewer ? "hidden" : "auto";
      return () => {
        document.body.style.overflow = "auto";
      };
    }, [imageViewer]);

    // to remove imageViewer if clicked other thing other than the image
    useEffect(() => {
      let handler = (event: any) => {
        if (
          !imageRef.current?.contains(event.target) &&
          !deleteRef.current?.contains(event.target) &&
          !nextIconRef.current?.contains(event.target) &&
          !previousIconRef.current?.contains(event.target)
        ) {
          handleImageViewerClose();
        }
      };

      document.addEventListener("mousedown", handler);

      return () => {
        document.removeEventListener("mousedown", handler);
      };
    }, [
      imageRef,
      deleteRef,
      nextIconRef,
      previousIconRef,
      handleImageViewerClose,
    ]);

    //to delete image data from firestore
    const handleDeleteImage = async () => {
      if (window.confirm("Are you sure you want to delete ?")) {
        try {
          setImageDeleting(true);
          await deleteDoc(doc(fireStoreDatabase, "gallery", currentImageId));
          dispatch(deleteImage(currentImageName));
          dispatch(deletefromGallery(currentImageId));
          handleImageViewerClose();
        } catch (err) {
          console.log(err);
          showSnackBar({ text: "Image data failed to delete", type: "error" });
        }
        setImageDeleting(false);
      }
    };

    //to show loading indicator until image is loaded in imageviewer
    const handleImageLoad = (): void => {
      setIsLoaded(true);
    };

    //to navigate image left on imageViewer
    const goToPreviousImage = useCallback(() => {
      if (currentImageIndex !== 0) {
        setIsLoaded(false);
        let currentIndex = currentImageIndex - 1;
        setCurrentImageIndex(currentIndex);
        setCurrentImageId(gallery[currentIndex]?.id);
        setCurrentImageName(gallery[currentIndex]?.imageName);
        setImageViewerSrc(gallery[currentIndex]?.image);
      }
    }, [
      currentImageIndex,
      setCurrentImageIndex,
      setCurrentImageId,
      setCurrentImageName,
      setImageViewerSrc,
      gallery,
      setIsLoaded,
    ]);

    //to navigate image right on imageViewer
    const goToNextImage = useCallback(() => {
      if (currentImageIndex !== gallery.length - 1) {
        setIsLoaded(false);
        let currentIndex = currentImageIndex + 1;
        setCurrentImageIndex(currentIndex);
        setCurrentImageId(gallery[currentIndex]?.id);
        setCurrentImageName(gallery[currentIndex]?.imageName);
        setImageViewerSrc(gallery[currentIndex]?.image);
      }
    }, [
      currentImageIndex,
      setCurrentImageIndex,
      setCurrentImageId,
      setCurrentImageName,
      setImageViewerSrc,
      gallery,
      setIsLoaded,
    ]);

    //to fire a function on right and left click
    const handleArrowKey = useCallback(
      (event: KeyboardEvent) => {
        if (event.keyCode === 39) {
          goToNextImage();
        } else if (event.keyCode === 37) {
          goToPreviousImage();
        }
      },
      [goToNextImage, goToPreviousImage]
    );

    useEffect(() => {
      if (currentImageIndex === gallery.length - 1) {
        fetchMoreImages();
      }
    }, [currentImageIndex, gallery, fetchMoreImages]);

    //to keep listening right and left click in the imageViewer
    useEffect(() => {
      if (imageViewer) {
        window.addEventListener("keydown", handleArrowKey);
        return () => {
          window.removeEventListener("keydown", handleArrowKey);
        };
      }
    }, [imageViewer, handleArrowKey]);

    return (
      <div className={imageViewer ? "imageViewer open" : "imageViewer"}>
        {/* <TransformWrapper
          initialScale={1}
          initialPositionX={200}
          initialPositionY={100}
        >
          <TransformComponent> */}
        {!isLoaded && <CircularProgress sx={{ color: "rgb(106, 100, 100)" }} />}
        <img
          src={imageViewerSrc}
          ref={imageRef}
          alt={currentImageName}
          onLoad={handleImageLoad}
          style={{ display: isLoaded ? "block" : "none" }}
        />
        {/* </TransformComponent>
        </TransformWrapper> */}

        {gallery[currentImageIndex]?.model && (
          <div className="image_model">
            📸{gallery[currentImageIndex]?.model}
          </div>
        )}

        <CloseIcon
          className="imageViewer_closeicon"
          onClick={handleImageViewerClose}
          sx={{
            fontSize: "3rem",
          }}
        />
        {currentImageIndex !== gallery.length - 1 && (
          <NavigateNextIcon
            className="nextIcon"
            onClick={goToNextImage}
            sx={{
              fontSize: "3rem",
            }}
            ref={nextIconRef}
          />
        )}
        {currentImageIndex !== 0 && (
          <NavigateBeforeIcon
            className="previousIcon"
            onClick={goToPreviousImage}
            sx={{
              fontSize: "3rem",
            }}
            ref={previousIconRef}
          />
        )}

        {currentUser?.displayName === "Prabin Thakur" &&
          currentUser?.emailVerified === true && (
            <div>
              {" "}
              {imageDeleting ? (
                <CircularProgress
                  sx={{
                    fontSize: "3rem",
                    color: "rgb(106, 100, 100)",
                  }}
                  className="progress"
                />
              ) : (
                <DeleteIcon
                  className="deleteIcon"
                  sx={{
                    fontSize: "3rem",
                  }}
                  onClick={() => handleDeleteImage()}
                  ref={deleteRef}
                />
              )}
            </div>
          )}
      </div>
    );
  }
);

export default React.memo(ImageViewer);
