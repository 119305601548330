import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_AUTHENTICATION_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_AUTHENTICATION_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_AUTHENTICATION_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_AUTHENTICATION_FIREBASE_STORAGE_BUCKET,
  messagingSenderId:
    process.env.REACT_APP_AUTHENTICATION_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_AUTHENTICATION_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_AUTHENTICATION_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const database = getDatabase(app);
export const fireStoreDatabase = getFirestore(app);
export const storage = getStorage(app);

//if there is only one google account in google and it was logged before then it will automatically fill in,  with this code it makes you choose account even if there is only one account
provider.setCustomParameters({
  prompt: "select_account",
});

// const analytics = getAnalytics(app);
