import "./index.scss";
import sign from "../../assets/svgs/sign.svg";
import Fade from "@mui/material/Fade";
import { useState } from "react";

const Preloader: React.FC<{ loading: boolean }> = ({ loading }) => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <Fade in={loading} timeout={{ enter: 100, exit: 500 }}>
      <div className="preloader_container">
        <div className="preloader_signature">
          <img
            className="signature"
            src={sign}
            alt="signature"
            onLoad={handleImageLoad}
          />
        </div>
        {imageLoaded && (
          <div className="loader">
            <span className="loader__element"></span>
            <span className="loader__element"></span>
            <span className="loader__element"></span>
          </div>
        )}
        <div></div>
      </div>
    </Fade>
  );
};

export default Preloader;
