import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { routes, protectedRoutes, loggedRoutes } from "./routes";
import Missing from "./components/Missing";
import Initialize from "./utils/Initialize";
import Preloader from "./components/Preloader";

const App: React.FC = () => {
  Initialize();
  const [minimumPreloaderTimeCrossed, setMinimumPreloaderTimeCrossed] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [contentLoaded, setContentLoaded] = useState<boolean>(false);

  useEffect(() => {
    const loadContent = () => {
      const handleReadyStateChange = () => {
        if (document.readyState === "complete") {
          setContentLoaded(true);
          if (minimumPreloaderTimeCrossed) {
            setLoading(false);
          }
        }
      };

      document.addEventListener("readystatechange", handleReadyStateChange);

      // Additional check for when the document is already in a complete state
      if (document.readyState === "complete") {
        handleReadyStateChange();
      }

      return () => {
        document.removeEventListener(
          "readystatechange",
          handleReadyStateChange
        );
      };
    };

    // Start the timer when the component mounts
    const timerId = setTimeout(() => {
      setMinimumPreloaderTimeCrossed(true);
      if (contentLoaded && loading) {
        setLoading(false);
      }
    }, 1000);

    loadContent();

    return () => {
      clearTimeout(timerId);
    };
  }, [minimumPreloaderTimeCrossed, contentLoaded, loading]);

  useEffect(() => {
    if (loading) {
      localStorage.setItem("maxedImage", "false");
    }
  }, [loading]);

  return (
    <>
      {loading ? (
        <Preloader loading={loading} />
      ) : (
        <Router>
          <Routes>
            {routes.map((item, index) => (
              <Route key={index} path={item.path} element={item.component} />
            ))}
            {protectedRoutes.map((item, index) => (
              <Route
                key={index}
                path={item.path}
                element={
                  JSON.parse(localStorage.getItem("isLoggedIn") || "false") ? (
                    <Navigate to="/" />
                  ) : (
                    item.component
                  )
                }
              />
            ))}
            {loggedRoutes.map((item, index) => (
              <Route
                key={index}
                path={item.path}
                element={
                  JSON.parse(localStorage.getItem("userId") || "null") ===
                  null ? (
                    <Navigate to="/login" />
                  ) : (
                    item.component
                  )
                }
              />
            ))}
            <Route path="*" element={<Missing />} />
          </Routes>
        </Router>
      )}
    </>
  );
};

export default App;
