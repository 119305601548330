import { useState } from "react";
import { Drawer } from "@mui/material";
import { hideSideBar } from "../../redux/sideBar/sideBarSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from "@mui/icons-material/Logout";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import useAuth from "../../customHooks/useAuth";
import { showContact } from "../../redux/contact/contactSlice";

const SideBar: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const sideBarShowing = useAppSelector((state) => state.sideBar);
  const { currentUser, loading, handleLogout } = useAuth();
  const [rotate, setRotate] = useState<boolean>(false);

  return (
    <Drawer
      className="sideBar_container"
      anchor="left"
      open={sideBarShowing}
      onClose={() => dispatch(hideSideBar())}
      PaperProps={{
        sx: {
          width: {
            xs: "100vw",
            sm: "20rem",
          },
        },
      }}
    >
      <div className="siderBar_elements">
        <CloseIcon
          className="sideBar_closeIcon"
          onClick={() => {
            setRotate(true);
            dispatch(hideSideBar());
            setTimeout(() => {
              setRotate(false);
            }, 200);
          }}
          sx={{
            fontSize: "3rem",
            transform: rotate ? "rotate(90deg)" : "none",
            transition: rotate ? "transform 0.1s ease" : "none",
          }}
        />
        <div className="drawer_links_container">
          <div
            className="drawer_items"
            style={{
              animation: "slide-in-left 0.5s ease-in-out both",
              transition: "animation 0.1s ease-in-out",
            }}
            onClick={() => {
              dispatch(hideSideBar());
              navigate("/");
            }}
          >
            HOME
          </div>
          <div
            className="drawer_items"
            style={{
              animation: "slide-in-left 0.5s ease-in-out 0.10s both",
              transition: "animation 0.1s ease-in-out",
            }}
            onClick={() => {
              dispatch(hideSideBar());
              navigate("/projects");
            }}
          >
            PROJECTS
          </div>
          <div
            className="drawer_items"
            style={{
              animation: "slide-in-left 0.5s ease-in-out 0.11s both",
              transition: "animation 0.1s ease-in-out",
            }}
            onClick={() => {
              dispatch(hideSideBar());
              navigate("/gallery");
            }}
          >
            GALLERY
          </div>
          <div
            className="drawer_items"
            style={{
              animation: "slide-in-left 0.5s ease-in-out 0.12s both",
              transition: "animation 0.1s ease-in-out",
            }}
            onClick={() => {
              dispatch(hideSideBar());
              navigate("/about");
            }}
          >
            ABOUT
          </div>
          <div
            className="drawer_items"
            style={{
              animation: "slide-in-left 0.5s ease-in-out 0.13s both",
              transition: "animation 0.1s ease-in-out",
            }}
            onClick={() => {
              dispatch(hideSideBar());
              dispatch(showContact());
            }}
          >
            CONTACT
          </div>
        </div>
        {currentUser ? (
          <button
            className="logout_icon"
            disabled={loading}
            onClick={() => {
              handleLogout();
              dispatch(hideSideBar());
            }}
          >
            <h2>LOG OUT</h2>
            &nbsp;&nbsp;
            <LogoutIcon />
          </button>
        ) : (
          <button
            className="signup_button"
            onClick={() => {
              navigate("/signup");
              dispatch(hideSideBar());
            }}
          >
            SIGN UP
          </button>
        )}
      </div>
    </Drawer>
  );
};

export default SideBar;
