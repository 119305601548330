import { useState, useRef, useEffect, useCallback } from "react";
import "./index.scss";
import SnackBar from "../../components/SnackBar";
import Contact from "../../components/Contact";
import useAuth from "../../customHooks/useAuth";
import Header from "../../components/Header";
import {
  populateGallery,
  uploadImageData,
} from "../../redux/gallery/gallerySlice";
import { showSnackBar } from "../../redux/snackBar/snackBarSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { InputLabel, TextareaAutosize } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { fireStoreDatabase, storage } from "../../firebase";
import ImageViewer from "../../components/ImageViewer";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import moment from "moment";
import Footer from "../../components/Footer";
import {
  collection,
  getDocs,
  orderBy,
  query,
  limit,
  startAfter,
} from "firebase/firestore";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
// import { Masonry } from "@mui/lab";
// import { useColumns } from "../../utils";

const Gallery: React.FC = () => {
  const { currentUser } = useAuth();
  const [memoizedGallery, setMemoizedGallery] = useState(
    useAppSelector((state) => state.gallery) || []
  );
  const dispatch = useAppDispatch();
  const [file, setFile] = useState<File | undefined | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [story, setStory] = useState<string>("");
  const [takenFrom, setTakenFrom] = useState<string>("");
  const [model, setModel] = useState<string>("");
  const [imageViewer, setImageViewer] = useState<boolean>(false);
  const [imageViewerSrc, setImageViewerSrc] = useState<string>("");
  const [currentImageId, setCurrentImageId] = useState<string>("");
  const [currentImageName, setCurrentImageName] = useState<string>("");
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [imageLoading, setImageLoading] = useState<boolean>(false);
  const maxedImage = JSON.parse(localStorage.getItem("maxedImage")!) || false;
  const [loadedImages, setLoadedImages] = useState<number[]>([]); // Keep track of loaded image indices

  //to make sure when page first loads you are on top of the page
  useEffect(() => {
    window.scrollTo(0, 0); // scrolls to the top of the page
  }, []);

  // const columns = useColumns();

  const handleImageLoad = (index: number) => {
    setLoadedImages((prevLoadedImages) => [...prevLoadedImages, index]);
  };

  // Check if all images in memoizedGallery are loaded
  const areAllImagesLoaded = useCallback(() => {
    if (memoizedGallery.length <= 0) return false;
    return loadedImages.length === memoizedGallery.length;
  }, [loadedImages, memoizedGallery]);

  const fetchGalleryData = useCallback(async () => {
    const lastDoc = memoizedGallery[memoizedGallery.length - 1] || null;
    try {
      setImageLoading(true);

      let queryBuilder = query(
        collection(fireStoreDatabase, "gallery"),
        orderBy("imageName", "desc"),
        limit(20)
      );

      if (lastDoc) {
        queryBuilder = query(
          collection(fireStoreDatabase, "gallery"),
          orderBy("imageName", "desc"),
          startAfter(lastDoc.imageName),
          limit(20)
        );
      }

      const querySnapshot = await getDocs(queryBuilder);

      if (querySnapshot.empty) {
        localStorage.setItem("maxedImage", "true");
      } else {
        const newGalleryData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        const existingImages = new Set(memoizedGallery.map((item) => item.id));

        let combinedImages = [
          ...memoizedGallery,
          ...newGalleryData.filter((item) => !existingImages.has(item.id)),
        ];

        setMemoizedGallery(combinedImages);
        dispatch(populateGallery(combinedImages));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setImageLoading(false);
    }
  }, [memoizedGallery, dispatch, setImageLoading]);

  const fetchMoreImages = useCallback(() => {
    if (!maxedImage && !imageLoading) {
      fetchGalleryData();
    } else {
      return;
    }
  }, [maxedImage, imageLoading, fetchGalleryData]);

  //for infinite scrolling
  useEffect(() => {
    const handleScroll = () => {
      // Calculate the scroll position
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      // Calculate the total height of the content, including the height of the hidden overflow
      const totalHeight = document.documentElement.scrollHeight;
      // Calculate the height of the viewport
      const windowHeight = window.innerHeight;

      // Trigger action every time the user scrolls above 90%
      if (scrollY + windowHeight >= totalHeight * 0.9 && areAllImagesLoaded()) {
        fetchMoreImages();
      }

      // Determine if the user has scrolled around 70% of the content
      if (scrollY + windowHeight >= totalHeight * 0.7 && areAllImagesLoaded()) {
        fetchMoreImages();
      }

      // Trigger action when the user reaches the bottom of the page
      if (scrollY + windowHeight >= totalHeight) {
        fetchMoreImages();
      }
    };

    if (maxedImage) {
      return;
    }

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Remove the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [fetchMoreImages, areAllImagesLoaded, maxedImage]);

  useEffect(() => {
    if (maxedImage) {
      return;
    }
    if (memoizedGallery.length === 0) {
      fetchGalleryData();
    }
  }, [maxedImage, fetchGalleryData, memoizedGallery]);

  // //to prevent right click on gallery
  // useEffect(() => {
  //   document.addEventListener("contextmenu", (event) => {
  //     event.preventDefault();
  //   });

  //   return () => {
  //     document.removeEventListener("contextmenu", (event) => {
  //       event.preventDefault();
  //     });
  //   };
  // }, []);

  //to prevent scrolling while imageViewer is on
  useEffect(() => {
    const handleScroll = (e: WheelEvent) => {
      if (imageViewer) {
        e.preventDefault();
      }
    };

    window.addEventListener("wheel", handleScroll, { passive: false });

    return () => {
      window.removeEventListener("wheel", handleScroll);
    };
  }, [imageViewer]);

  //to upload image on storage firebase
  const handleUploadSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!file) {
      alert("empty");
      return;
    }
    setLoading(true);

    const name = moment().format() + file.name;

    const storageRef = ref(storage, name);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => {
        dispatch(
          showSnackBar({ text: "Image failed to be deleted", type: "success" })
        );
        console.log(error);
      },
      //uploading image data on firestore after image uploading on storage is successful
      () => {
        setLoading(false);
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          dispatch(
            uploadImageData({
              title: title || null,
              story: story || null,
              takenFrom: takenFrom || null,
              model: model || null,
              imageName: name,
              image: downloadURL,
            })
          );
        });

        handleFileRemove();
        setTitle("");
        setStory("");
        setTakenFrom("");
        setModel("");
      }
    );
  };

  //details to show on imageViewer
  const getImage = (id: string, name: string, image: string, index: number) => {
    setCurrentImageId(id);
    setCurrentImageName(name);
    setImageViewerSrc(image);
    setCurrentImageIndex(index);
    setImageViewer(true);
  };

  const handleFileRemove = (): void => {
    setFile(null);
    if (inputFileRef.current) {
      inputFileRef.current.value = "";
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    setTakenFrom(event.target.value as string);
  };

  return (
    <div
      // className={`gallery_container ${!isScrolling ? "hide_scrollbar" : ""}`}
      className="gallery_container"
    >
      {currentUser?.displayName === "Prabin Thakur" &&
        currentUser?.emailVerified === true &&
        currentUser?.email === "thakurprabin2017@gmail.com" && (
          <form
            onSubmit={handleUploadSubmit}
            className="gallery_uploading_container"
          >
            <TextField
              id="outlined-basic"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              label="Title"
              variant="outlined"
            />
            <TextareaAutosize
              className="gallery_text_area"
              name="story"
              minRows={3}
              value={story}
              onChange={(e) => setStory(e.target.value)}
              placeholder="Story..."
              // @ts-ignore
              maxLength="300"
            />
            <InputLabel id="demo-simple-select-label">Taken From</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={takenFrom}
              label="Taken From"
              onChange={handleChange}
              sx={{ width: "15.1rem" }}
            >
              <MenuItem value={"SmartPhone"}>SmartPhone</MenuItem>
              <MenuItem value={"Camera"}>Camera</MenuItem>
            </Select>

            <TextField
              id="outlined-basic"
              label="Model"
              value={model}
              onChange={(e) => setModel(e.target.value)}
              variant="outlined"
            />
            <TextField
              id="outlined-basic"
              type="file"
              ref={inputFileRef}
              onChange={(event) => {
                setFile((event.target as HTMLInputElement).files?.[0]);
              }}
              variant="outlined"
              sx={{ width: "17rem" }}
            />
            {loading ? (
              <CircularProgress />
            ) : (
              <button
                type="submit"
                disabled={loading}
                className="upload_submit_button"
                style={{ marginBottom: "20px" }}
              >
                Submit
              </button>
            )}
          </form>
        )}
      <Header />
      {memoizedGallery.length === 0 ? (
        <div className="skeleton_container">
          <Skeleton
            className="skeleton"
            variant="rectangular"
            width={280}
            height={350}
          ></Skeleton>
          <Skeleton
            className="skeleton"
            variant="rectangular"
            width={280}
            height={350}
          ></Skeleton>
          <Skeleton
            className="skeleton"
            variant="rectangular"
            width={280}
            height={350}
          ></Skeleton>
          <Skeleton
            className="skeleton"
            variant="rectangular"
            width={280}
            height={350}
          ></Skeleton>
          <Skeleton
            className="skeleton"
            variant="rectangular"
            width={280}
            height={350}
          ></Skeleton>
        </div>
      ) : (
        <>
          {/* For Masonry layout using columns */}
          {/* <div className="gallery_masonry">
            {memoizedGallery.length !== 0 &&
              memoizedGallery.map((image, index) => (
                <div
                  className="gallery_masonry_image_container"
                  key={image.id}
                  onClick={() => {
                    getImage(image.id, image.imageName, image.image, index);
                  }}
                >
                  <LazyLoadImage
                    className="gallery_masonry_image"
                    alt={image.imageName}
                    effect="blur"
                    src={image.image}
                  />
                </div>
              ))}
          </div> */}
          {/* For Masonry layout using muilab masonry */}
          {/* <Masonry
            columns={columns}
            spacing={1.5}
            className="gallery_masonry_mui"
          >
            {memoizedGallery.length !== 0 &&
              memoizedGallery.map((image, index) => (
                <LazyLoadImage
                  className="gallery_masonry_mui_image"
                  alt={image.imageName}
                  effect="blur"
                  srcSet={`${image.image}?w=162&auto=format&dpr=2 2x`}
                  src={`${image.image}?w=162&auto=format`}
                  onClick={() => {
                    getImage(image.id, image.imageName, image.image, index);
                  }}
                />
              ))}
          </Masonry> */}

          {/* For Masonry layout using masonry package */}
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 499: 1, 500: 2, 900: 3, 1200: 4 }}
            className="gallery_masonry_package_container"
          >
            <Masonry style={{ padding: "10px" }} gutter="10px">
              {memoizedGallery.length !== 0 &&
                memoizedGallery.map((image: any, index: number) => (
                  <LazyLoadImage
                    key={index}
                    className="gallery_masonry_package_image"
                    alt={image.imageName}
                    effect="blur"
                    src={image.image}
                    onClick={() => {
                      getImage(image.id, image.imageName, image.image, index);
                    }}
                    afterLoad={() => handleImageLoad(index)}
                  />
                ))}
            </Masonry>
          </ResponsiveMasonry>
          {imageLoading && !maxedImage ? (
            <p
              style={{
                width: "17rem",
                height: "2.5rem",
                marginBottom: "2rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress
                style={{
                  height: "30px",
                  width: "30px",
                }}
              />
            </p>
          ) : !imageLoading && !maxedImage ? (
            <p
              className="home_about_button"
              style={{ marginBottom: "2rem" }}
              onClick={fetchMoreImages}
            >
              LOAD MORE
            </p>
          ) : null}

          {maxedImage && (
            <p
              style={{
                width: "17rem",
                height: "2.5rem",
                marginBottom: "2rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                letterSpacing: "0.5rem",
              }}
            >
              END
            </p>
          )}

          <Footer />
        </>
      )}

      <ImageViewer
        gallery={memoizedGallery}
        imageViewer={imageViewer}
        imageViewerSrc={imageViewerSrc}
        currentImageIndex={currentImageIndex}
        setImageViewer={setImageViewer}
        setImageViewerSrc={setImageViewerSrc}
        setCurrentImageId={setCurrentImageId}
        setCurrentImageName={setCurrentImageName}
        currentImageId={currentImageId}
        currentImageName={currentImageName}
        setCurrentImageIndex={setCurrentImageIndex}
        fetchMoreImages={fetchMoreImages}
      />
      <SnackBar />
      <Contact />
    </div>
  );
};

export default Gallery;
