import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import "./index.scss";
import { responsiveFontSizes, ThemeProvider } from "@mui/material/styles";
import defaultTheme from "./theme";

const customTheme = responsiveFontSizes(defaultTheme);
const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement!);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={customTheme}>
      <App />
    </ThemeProvider>
  </Provider>
);
