import "./index.scss";
import Header from "../../components/Header";
import SnackBar from "../../components/SnackBar";
import Contact from "../../components/Contact";
import Footer from "../../components/Footer";
import todoAppi from "../../assets/images/todoAppi.png";
import todoAppii from "../../assets/images/todoAppii.png";
import todoAppiii from "../../assets/images/todoAppiii.png";
import memoryGame from "../../assets/images/memoryGame.jpg";
import budgetApp from "../../assets/images/budgetApp.jpg";
import pizzaSite from "../../assets/images/pizzaSite.jpg";
import gallery from "../../assets/images/gallery.jpg";
import techdeni from "../../assets/images/techdeni.jpg";
import techdenii from "../../assets/images/techdenii.png";
import techdeniii from "../../assets/images/techdeniii.png";
import techdeniv from "../../assets/images/techdeniv.png";
import techdenv from "../../assets/images/techdenv.png";
import techdenvi from "../../assets/images/techdenvi.png";
import CSRi from "../../assets/images/CSRi.png";
import CSRii from "../../assets/images/CSRii.png";
import CSRiii from "../../assets/images/CSRiii.png";
import CSRiv from "../../assets/images/CSRiv.png";
import CSRv from "../../assets/images/CSRv.png";
import raffeei from "../../assets/images/raffeei.png";
import raffeeii from "../../assets/images/raffeeii.png";
import raffeeiii from "../../assets/images/raffeeiii.png";
import raffeeiv from "../../assets/images/raffeeiv.png";
import raffeev from "../../assets/images/raffeev.png";
import novai from "../../assets/images/novai.png";
import novaii from "../../assets/images/novaii.png";
import novaiii from "../../assets/images/novaiii.png";
import novaiv from "../../assets/images/novaiv.png";
import novav from "../../assets/images/novav.png";
import novavi from "../../assets/images/novavi.png";
import posi from "../../assets/images/posi.png";
import posii from "../../assets/images/posii.png";
import posiii from "../../assets/images/posiii.png";
import posiv from "../../assets/images/posiv.png";
import posv from "../../assets/images/posv.png";
import posvi from "../../assets/images/posvi.png";
import posvii from "../../assets/images/posvii.png";
import posviii from "../../assets/images/posviii.png";
import { useNavigate } from "react-router-dom";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import { useEffect } from "react";
import Slider from "react-slick";

const Projects: React.FC = () => {
  const navigate = useNavigate();

  //to make sure when page first loads you are on top of the page
  useEffect(() => {
    window.scrollTo(0, 0); // scrolls to the top of the page
  }, []);

  const sliderSettings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    dots: true,
  };

  return (
    <div className="projects_container">
      <Header />
      <div className="projects_items">
        <div className="project_item">
          <div className="project_item_image_container">
            <Slider {...sliderSettings} className="projects_slider">
              <img src={posi} alt="posi" className="project_item_image" />
              <img src={posii} alt="posii" className="project_item_image" />
              <img src={posiii} alt="posiii" className="project_item_image" />
              <img src={posiv} alt="posiv" className="project_item_image" />
              <img src={posv} alt="posv" className="project_item_image" />
              <img src={posvi} alt="posvi" className="project_item_image" />
              <img src={posvii} alt="posvii" className="project_item_image" />
              <img src={posviii} alt="posviii" className="project_item_image" />
            </Slider>
          </div>
          <div className="project_item_title">All-in-One POS</div>
          <div className="project_item_description">
            An all-encompassing restaurant POS system, seamlessly managing
            orders for delivery, online, dine-in, and takeaway. Beyond advanced
            filters and handling payment it includes bill printing,
            notifications, KOT, order editing, shift management, analytics and
            more. It's the ultimate solution for versatile and efficient
            point-of-sale management. Made using :
            <br />
            <span className="project_item_made_with">
              React/Typescript/Redux/Tailwind/Material UI/SOCKET
            </span>
          </div>

          <div className="project_item_button_container">
            <div className="project_item_button">View Code</div>
            <div className="project_item_button">show Demo</div>
          </div>
        </div>
        <div className="project_item">
          <div className="project_item_image_container">
            <Slider {...sliderSettings} className="projects_slider">
              <img src={novai} alt="novai" className="project_item_image" />
              <img src={novaii} alt="novaii" className="project_item_image" />
              <img src={novaiii} alt="novaiii" className="project_item_image" />
              <img src={novaiv} alt="novaiv" className="project_item_image" />
              <img src={novav} alt="novav" className="project_item_image" />
              <img src={novavi} alt="novavi" className="project_item_image" />
            </Slider>
          </div>
          <div className="project_item_title">Food Delivery SITE</div>
          <div className="project_item_description">
            A comprehensive food delivery website, offering a user-friendly
            interface for ordering, cart management and more. Alongside, a
            versatile dashboard which allows for manual order processing, orders
            and sales analytics, reviews, change menu, shift management,
            printing bills, notifications, KOT (Kitchen Order Tickets) and many
            more. Made using :
            <br />
            <span className="project_item_made_with">
              React/Material UI/CSS/SOCKET
            </span>
          </div>

          <div className="project_item_button_container">
            <div className="project_item_button">View Code</div>
            <div className="project_item_button">show Demo</div>
          </div>
        </div>
        <div className="project_item">
          <div className="project_item_image_container">
            <Slider {...sliderSettings} className="projects_slider">
              <img src={CSRi} alt="CSRi" className="project_item_image" />
              <img src={CSRii} alt="CSRii" className="project_item_image" />
              <img src={CSRiii} alt="CSRiii" className="project_item_image" />
              <img src={CSRiv} alt="CSRiv" className="project_item_image" />
              <img src={CSRv} alt="CSRv" className="project_item_image" />
            </Slider>
          </div>
          <div className="project_item_title">POS</div>
          <div className="project_item_description">
            A hotel management POS simplifying order handling from websites and
            call-in orders. Manage order creation, editing, and rider
            assignments effortlessly, streamlining the workflow. Track orders
            with ease and simplify the entire process, ensuring successful
            deliveries. Made using :
            <br />
            <span className="project_item_made_with">
              React/Typescript/Material UI/CSS/SOCKET
            </span>
          </div>

          <div className="project_item_button_container">
            <div className="project_item_button">View Code</div>
            <div className="project_item_button">show Demo</div>
          </div>
        </div>
        <div className="project_item">
          <div className="project_item_image_container">
            <Slider {...sliderSettings} className="projects_slider">
              <img src={raffeei} alt="raffeei" className="project_item_image" />
              <img
                src={raffeeii}
                alt="raffeeii"
                className="project_item_image"
              />
              <img
                src={raffeeiii}
                alt="raffeeiii"
                className="project_item_image"
              />
              <img
                src={raffeeiv}
                alt="raffeeiv"
                className="project_item_image"
              />
              <img src={raffeev} alt="raffeev" className="project_item_image" />
            </Slider>
          </div>
          <div className="project_item_title">E-COMMERCE SITE</div>
          <div className="project_item_description">
            This is a go-to solution for seamless online shopping with a
            responsive website and an intuitive dashboard, enjoy features like
            search, efficient filters, and a user-friendly cart system—all
            seamlessly integrated for a simplified experience in one
            comprehensive platform. Made using :
            <br />
            <span className="project_item_made_with">
              Next JS/Typescript/Redux toolkit/CSS
            </span>
          </div>

          <div className="project_item_button_container">
            <div className="project_item_button">View Code</div>
            <div className="project_item_button">show Demo</div>
          </div>
        </div>

        <div className="project_item">
          <div className="project_item_image_container">
            <Slider {...sliderSettings} className="projects_slider">
              <img
                src={todoAppi}
                alt="todoAppi"
                className="project_item_image"
              />
              <img
                src={todoAppii}
                alt="todoAppii"
                className="project_item_image"
              />
              <img
                src={todoAppiii}
                alt="todoAppiii"
                className="project_item_image"
              />
            </Slider>
          </div>
          <div className="project_item_title">Todo App</div>
          <div className="project_item_description">
            A simple todo app with drag and drop functionality that enables
            users to effortlessly rearrange their tasks by dragging them to a
            new position, while keeping track of their todo lists. Made using :
            <br />
            <span className="project_item_made_with">
              React/Typescript/Firebase/React Beautiful Dnd/SCSS
            </span>
          </div>
          <div style={{ textAlign: "center" }}>
            <p>For Demo:</p>
            <p>Email: test@gmail.com</p>
            <p>Password: test123</p>
          </div>
          <div className="project_item_button_container">
            <div className="project_item_button"> View Code</div>
            <div
              className="project_item_button"
              onClick={() => navigate("/todoApp")}
            >
              show Demo
            </div>
          </div>
        </div>

        <div className="project_item">
          <div className="project_item_image_container">
            <Slider {...sliderSettings} className="projects_slider">
              <img
                src={techdeni}
                alt="techdeni"
                className="project_item_image"
              />
              <img
                src={techdenii}
                alt="techdenii"
                className="project_item_image"
              />
              <img
                src={techdeniii}
                alt="techdeniii"
                className="project_item_image"
              />
              <img
                src={techdeniv}
                alt="techdeniv"
                className="project_item_image"
              />
              <img
                src={techdenv}
                alt="techdenv"
                className="project_item_image"
              />
              <img
                src={techdenvi}
                alt="techdenvi"
                className="project_item_image"
              />
            </Slider>
          </div>
          <div className="project_item_title">E-COMMERCE SITE</div>
          <div className="project_item_description">
            This tech e-commerce website offers a user-friendly interface with
            responsive design for a seamless shopping experience. Features
            includes search and filtering, authentication and add to cart, etc.
            Made using :
            <br />
            <span className="project_item_made_with">
              React/Typescript/Firebase/Redux/SCSS/Material UI
            </span>
          </div>

          <div className="project_item_button_container">
            <a
              href="https://github.com/Prabin-Thakur/techden"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <div className="project_item_button"> View Code</div>
            </a>
            <a
              href="https://tech-den.netlify.app/"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <div className="project_item_button">show Demo</div>
            </a>
          </div>
        </div>
        <div className="project_item">
          <div className="project_item_image_container">
            <img src={gallery} alt="todoApp" className="project_item_image" />
          </div>
          <div className="project_item_title">Gallery</div>
          <div className="project_item_description">
            A simple gallery app that enables users to view and interact with a
            collection of photos. This app features a responsive design,
            allowing it to adapt seamlessly to different screen sizes and
            devices. Users can easily interact with them by clicking, zooming,
            or swiping. Made using :
            <br />
            <span className="project_item_made_with">
              React/Firebase/Typescript/SCSS
            </span>
          </div>
          <div className="project_item_button_container">
            <div className="project_item_button"> View Code</div>
            <div
              className="project_item_button"
              onClick={() => navigate("/gallery")}
            >
              show Demo
            </div>
          </div>
        </div>
        <div className="project_item">
          <div className="project_item_image_container">
            <img
              src={memoryGame}
              alt="todoApp"
              className="project_item_image"
            />
          </div>
          <div className="project_item_title">Memory Game</div>
          <div className="project_item_description">
            A simple memory game built with pure JS is a fun and engaging way
            for users to test their memory skills. This game has beautiful
            design which also features a grid of cards that users can flip over
            to reveal images and enjoy as they play along. Made using :
            <br />
            <span className="project_item_made_with">HTLML/CSS/Javascript</span>
          </div>
          <div className="project_item_button_container">
            <a
              href="https://github.com/Prabin-Thakur/Memory-Game"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <div className="project_item_button"> View Code</div>
            </a>
            <a
              href="https://memoory-game.netlify.app/"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <div className="project_item_button">show Demo</div>
            </a>
          </div>
        </div>
        <div className="project_item">
          <div className="project_item_image_container">
            <img src={budgetApp} alt="todoApp" className="project_item_image" />
          </div>
          <div className="project_item_title">Budget App</div>
          <div className="project_item_description">
            A simple budget app that allows users to easily manage their
            finances by tracking their budgets and expenses. This app features a
            user-friendly interface and design enabling users to track their
            spending in detail. Made using :
            <br />
            <span className="project_item_made_with">React/Bootstrap</span>
          </div>
          <div className="project_item_button_container">
            <a
              href="https://github.com/Prabin-Thakur/Budget-App"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <div className="project_item_button"> View Code</div>
            </a>
            <a
              href="https://budgeet-app.netlify.app/"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <div className="project_item_button">show Demo</div>
            </a>
          </div>
        </div>
        <div className="project_item">
          <div className="project_item_image_container">
            <img src={pizzaSite} alt="todoApp" className="project_item_image" />
          </div>
          <div className="project_item_title">Pizza Website</div>
          <div className="project_item_description">
            A simple pizza website which is visually appealing with
            user-friendly interface for showcasing a pizza restaurant's menu and
            services. This website features a responsive design, making it
            accessible and easy to navigate on various devices with its modern
            design and functional features. Made using :
            <br />
            <span className="project_item_made_with">HTML/CSS/Javascript</span>
          </div>
          <div className="project_item_button_container">
            <a
              href="https://github.com/Prabin-Thakur/Pizza-Website"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <div className="project_item_button"> View Code</div>
            </a>
            <a
              href="https://pizza-sitee.netlify.app/"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <div className="project_item_button">show Demo</div>
            </a>
          </div>
        </div>
      </div>
      <SnackBar />
      <Contact />
      <Footer />
    </div>
  );
};

const CustomNextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", marginLeft: "20px" }}
      onClick={onClick}
    >
      <KeyboardArrowRightRoundedIcon />
    </div>
  );
};

const CustomPrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <KeyboardArrowLeftRoundedIcon />
    </div>
  );
};

export default Projects;
